


















































































































































































































































































































































































import { Component } from 'vue-property-decorator';
import i18n from '@/services/i18n';
import { Ax } from '@/utils';
import { SingleBudgetProgramField } from '../budget-programs';
import { SingleBudgetVariantField } from '../budget-variants';
import * as common from '../common';
import { ComponentBase, Overlay, SvgIcon } from '../components';
import { SingleOrgField } from '../organizations';
import { BudgetVariants, Comp, Dict, Org, Report, Report2, Version } from '../types';
import { SingleVersionField } from '../versions';
import axios from "axios";


const events = {
    reportSaved: 'report-saved',
};

const translates = {
    tools: {
        buttons: {
            get downloadReport(): string {
                return i18n.choose(
                    'Скачать',
                    'Жүктеп алу',
                    'Download',
                );
            },
            get generateReport(): string {
                return i18n.choose(
                    'Сформировать',
                    'Пішін',
                    'Generate',
                );
            }
        }
    },
    errors: {
        get cannotLoadBudgetSubprograms(): string {
            return i18n.choose(
                'Cannot load budget subprograms',
                'Не удалось загрузить бюджетные подпрограммы (каз)',
                'Не удалось загрузить бюджетные подпрограммы',
            );
        },
    },
    fields: {
        budgetSubprogram: {
            get title(): string {
                return i18n.choose(
                    'Бюджетная подпрограмма',
                    'Бюджетная подпрограмма (каз)',
                    'Budget subprogram',
                );
            },
            get emptyValue(): string {
                return i18n.choose(
                    '[ Нет значения ]',
                    '[ Нет значения ] (каз)',
                    '[ No value ]',
                );
            },
        },
    },
};


@Component({
    components: {
        Overlay,
        SingleBudgetProgramField,
        SingleBudgetVariantField,
        SingleOrgField,
        SingleVersionField,
        SvgIcon,
    },
})
export default class GenerationPane extends ComponentBase {
    constructor() {
        super('modules.budget.staffing_table.reports.*XlsxGenerationPane*');
    }


    // region Lifecycle
    protected created() {
        super.created();

        this.reportYearString = this.reportYear.toString();

        // region Данные
        this.$watch('org', (org: Org | null) => {
            common.store.org = org;
            common.store.conditionExp = null
            this.resetVersion()
            this.resetBudgetProgram()
            this.updateBudgetVariant(org)

        });
        this.$watch('requiredRegionCode', (/* requiredRegionCode: string | null */) => {
            this.reloadRegion();
        });
        this.$watch('version', (version: Version | null) => {
            common.store.version = version;
        });
        this.$watch('reportYear', (reportYear: number) => {
            common.store.reportYear = reportYear;
            common.store.conditionExp = null
            this.updateBudgetVariant(this.org)
            this.loadInitialBudgetProgram();
            this.reloadSpecificities();
        });
        this.$watch('reportYearString', (/* reportYearString: string */) => {
            this.readReportYearString();
            this.resetBudgetVariant();
        });
        this.$watch('budgetVariant', (budgetVariant: BudgetVariants | null) => {
            common.store.budgetVariant = budgetVariant;
        });
        this.$watch('budgetProgram', (budgetProgram: Dict.EbkFunc | null) => {
            common.store.budgetProgram = budgetProgram;
            this.budgetSubprogram = null;
            if (this.year !== null) {
                this.loadBudgetSubprograms();
            }
        });
        this.$watch('specificity', (specificity: Dict.EbkEk | null) => {
            common.store.specificity = specificity;

            const specificityCode = specificity?.spf ?? null;
            if (specificityCode === null) {
                this.form = null;
            } else {
                const forms = common.specificityToReportFormKeyMap.get(specificityCode);
                // noinspection JSIncompatibleTypesComparison
                if (forms === undefined) {
                    this.form = null;
                } else {
                    const index = forms.findIndex((testedForm) => (testedForm === this.form));
                    if (index < 0) {
                        this.form = null;
                    }
                }
            }
        });
        this.$watch('form', (form: Report.FormKey | null) => {
            common.store.form = form;
        });
        // endregion
    }

    protected mounted() {
        super.mounted();

        this.reloadRegion();
        this.loadInitialBudgetVariant();
        this.loadInitialBudgetProgram();
        this.reloadSpecificities();

        if ((this.year !== null) && (this.budgetProgram !== null)) {
            this.loadBudgetSubprograms();
        }
    }
    // endregion


    // region Утилиты
    public translates = translates;

    public get generationAndSavingForbidden(): boolean {
        return this.budgetVariantForbiddenForGenerationAndSending || this.detailedReport || this.debugging;
    }
    // endregion


    // region Общие данные
    public get loading(): boolean {
        return (this.regionLoading || this.budgetVariantLoading || this.budgetProgramLoading || this.budgetSubprogramLoading || this.specificitiesLoading || this.generatingAndSavingReport);
    }

    public get year(): number | null {
        const version = this.version;
        if (version === null) {
            return null;
        } else {
            return version.year;
        }
    }
    // endregion


    // region Организация, ГУ, КГКП, АБП
    public org: Org | null = common.store.org;

    public get kgkp(): Dict.GuKgkp | null {
        const org = this.org;
        if ((org === null) || (org.type !== 'KGKP')) {
            return null;
        }
        return org.kgkp;
    }

    public get gu(): Dict.Gu | null {
        const org = this.org;
        if (org === null) {
            return null;
        }

        const type = org.type;
        switch (org.type) {
            case 'KGKP':
                return org.gu;
            case 'GU':
                return org.gu;
            default:
                console.error(`Unexpected org type \"${type}\"`);
                return null;
        }
    }

    public get guText(): string | null {
        const gu = this.gu;
        if (gu === null) {
            return null;
        }

        const title = this.localize(
            () => (gu.nameEn ?? gu.nameRu),
            () => (gu.nameKk ?? gu.nameRu),
            () => (gu.nameRu),
        )

        return `${gu.code} - ${title}`;
    }

    public get abpCode(): number | null {
        const guCode = this.gu?.code ?? null;

        if (guCode === null) {
            return null;
        }
        if (guCode.length < 3) {
            return null;
        }

        const abpCodeString = guCode.substring(0, 3);
        const abpCode = parseInt(abpCodeString);

        if (String(abpCode) !== abpCodeString) {
            console.error(`GU code "${guCode}" contains invalid ABP code "${abpCodeString}"`);
            return null;
        }
        return abpCode;
    }
    // endregion


    // region Регион
    public get requiredRegionCode(): string | null {
        const gu =  this.gu;
        if (gu === null) return null;

        let result: string;
        if (gu.budgetType === '02') {
            const idRegion = gu.idRegion;
            const idRegionPart = (
                idRegion.length > 2 ?
                    idRegion.substr(0, 2)
                    :
                    idRegion
            );
            result = idRegionPart + '0101';
        } else {
            result = gu.idRegion;
        }

        return result;
    }

    public regionLoading = false;
    public region: Dict.BudgetRegions | null = null;

    public get regionCode(): string | null {
        const region = this.region;
        if (region === null) {
            return null;
        }
        return region.code;
    }

    public get regionText(): string | null {
        const region = this.region;
        if (region === null) {
            return null;
        }

        const title = this.localize(
            () => (region.nameRu ?? region.code ?? region.id?.toString() ?? ''),
            () => (region.nameKk ?? region.nameRu ?? region.code ?? region.id?.toString() ?? ''),
            () => (region.nameRu ?? region.code ?? region.id?.toString() ?? ''),
        );

        const code = (region.code ?? region.id?.toString() ?? '');

        return `${code} - ${title}`;
    }

    public reloadRegion() {
        if (this.regionLoading) {
            console.error('Cannot reload region - another loading is running');
            return;
        }

        const requiredRegionCode = this.requiredRegionCode;
        if (requiredRegionCode === null) {
            console.error('Cannot load region - required region code is null');
            return;
        }

        this.regionLoading = true;
        this.region = null;
        this.request<Dict.BudgetRegions | null>(
            { url: `/api/budget/staffing_table/budget-regions/by-code/${requiredRegionCode}` },
            (data) => {
                this.region = data;
            },
            'Error while loading region',
            () => {
                this.regionLoading = false;
            },
        )
    }
    // endregion


    // region Версия
    public version: Version | null = common.store.version;
    // endregion


    // region Год отчета
    public reportYear: number = common.store.reportYear;
    public reportYearString: string = '';

    public readReportYearString() {
        try {
            const number = parseInt(this.reportYearString);
            if (!Number.isSafeInteger(number)) {
                return;
            }
            if (number.toString(10) !== this.reportYearString.trim()) {
                return;
            }
            this.reportYear = number;
        } catch (e) {}
    }
    // endregion

    private resetBudgetVariant() {
        this.budgetVariant = null
    }

    private resetVersion() {
        this.version = null
    }

    private resetBudgetProgram() {
        this.budgetProgram = null
    }

    // region Вариант бюджета
    public budgetVariantLoading = false;
    public budgetVariant: BudgetVariants | null = common.store.budgetVariant;
    public budgetVariantInitCancelled = false;

    public get budgetVariantForbiddenForGenerationAndSending(): boolean {
        const budgetVariant = this.budgetVariant;
        if (budgetVariant === null) {
            return false;
        }

        const budgetVariantYear = budgetVariant.year;
        if (budgetVariantYear === null) {
            return false;
        }

        const reportYear = this.reportYear;
        return (budgetVariantYear !== reportYear);
    }

    public loadInitialBudgetVariant() {
        const initialBudgetVariant = common.store.budgetVariant;
        if (initialBudgetVariant === null) {
            return;
        }

        if (this.budgetVariantLoading) {
            console.error('Cannot load initial budget variant - another loading is running');
            return;
        }

        if (this.org === null) {
            console.error('Cannot load initial budget variant - organization is not selected');
            return;
        }

        const orgCode = this.org.org.code;
        const year = this.reportYear
        this.budgetVariantLoading = true;

        const url = this.prepareUrl(
            `/api/budget/staffing_table/budget-variants`,
            [
                ['org-code', orgCode],
                ['year', year],
            ]
        );
        Ax<Array<BudgetVariants>>(
            { url },
            (data) => {
                if (this.budgetVariantInitCancelled) {
                    return;
                }

                const index = data.findIndex((budgetVariant) => (budgetVariant.variantUuid === initialBudgetVariant.variantUuid));
                if (index >= 0) {
                    this.budgetVariant = initialBudgetVariant;
                }
            },
            (error) => {
                this.toast(
                    'danger',
                    this.i18n.translate('errors.cannot_load_budget_variants'),
                    error.toString(),
                );
            },
            () => {
                this.budgetVariantLoading = false;
            },
        );
    }

    async updateBudgetVariant(org: Org | null) {
        this.budgetVariant = org ? await this.setActualVariant() : null;
    }

     async setActualVariant(): Promise<BudgetVariants | null> {
         this.budgetVariantLoading = true;

        const orgCode = this.org?.org.code;
        const year = this.reportYear
        const url = this.prepareUrl(
            '/api/budget/staffing_table/budget-variants',
            [
                ['org-code', orgCode],
                ['year', year],
            ],
        );
        try {
            const response = await axios.get<Array<BudgetVariants>>(url);
            return  response.data.find(variant => variant.attribute === true) ?? null;
        } catch (error) {
            console.error(`Error retrieving the actual variant for the year ${year}:`, error);
            return null;
        }
        finally {
            this.budgetVariantLoading = false;
        }
    }
    // endregion


    // region Бюджетная программа
    public budgetProgramLoading = false;
    public budgetProgram: Dict.EbkFunc | null = null;
    public budgetProgramInitCancelled = false;

    public loadInitialBudgetProgram() {
        const initialBudgetProgram = common.store.budgetProgram;
        if (initialBudgetProgram === null) {
            return;
        }

        if (this.budgetProgramLoading) {
            console.error('Cannot load initial budget program - another loading is running');
            return;
        }

        const reportYear = this.reportYear;
        const abpCode = this.abpCode;
        if (abpCode === null) {
            console.error('Cannot load initial budget program - ABP code is null');
            return;
        }

        this.budgetProgramLoading = true;

        const url = this.prepareUrl(
            `/api/budget/staffing_table/budget-programs`,
            [
                ['abp-code', abpCode],
                ['year', reportYear],
            ]
        );
        Ax<Array<Dict.EbkFunc>>(
            { url },
            (data) => {
                if (this.budgetProgramInitCancelled) {
                    return;
                }

                const index = data.findIndex((budgetProgram) => (budgetProgram.id === initialBudgetProgram.id));
                if (index >= 0) {
                    this.budgetProgram = initialBudgetProgram;
                }
            },
            (error) => {
                this.toast(
                    'danger',
                    this.i18n.translate('errors.cannot_load_budget_programs'),
                    error.toString(),
                );
            },
            () => {
                this.budgetProgramLoading = false;
            },
        );
    }
    // endregion


    // region Бюджетная подпрограмма
    public budgetSubprogramLoading = false;
    public budgetSubprograms: Array<Dict.EbkFunc> = [];
    public budgetSubprogram: Dict.EbkFunc | null = null;

    public loadBudgetSubprograms() {
        if (this.budgetSubprogramLoading) {
            console.error('Cannot load budget subprograms - another loading is running');
            return;
        }

        const budgetProgram = this.budgetProgram;
        if (budgetProgram === null) {
            console.error('Cannot load budget subprograms - budget program is NULL');
            return;
        }

        const year = this.year;
        if (year === null) {
            console.error('Cannot load budget subprograms - year is NULL');
            return;
        }

        const funcGroupCode = budgetProgram.gr!!;
        const funcSubgroupCode = budgetProgram.pgr!!;
        const abpCode = budgetProgram.abp!!;
        const budgetProgramCode = budgetProgram.prg!!;
        const date = new Date(year, 0, 1, 0, 0, 0, 0).getTime();

        const url = this.prepareUrl(
            '/api/budget/staffing_table/report/budget-subprograms',
            [
                ['func-group-code', funcGroupCode],
                ['func-subgroup-code', funcSubgroupCode],
                ['abp-code', abpCode],
                ['budget-program-code', budgetProgramCode],
                ['date', date],
            ],
        );

        this.budgetSubprogramLoading = true;
        this.budgetSubprogram = null;
        this.budgetSubprograms = [];

        this.request<Array<Dict.EbkFunc>>(
            { url },
            (data) => {
                this.budgetSubprograms = data;
            },
            () => (translates.errors.cannotLoadBudgetSubprograms),
            () => {
                this.budgetSubprogramLoading = false;
            },
        );
    }

    public getBudgetSubprogramTitle(item: Dict.EbkFunc): string {
        let code = (item.ppr!!).toString();
        while (code.length < 3) {
            code = '0' + code;
        }

        const name = i18n.choose(
            () => (item.nameRu ?? item.id!!.toString()),
            () => (item.nameKk ?? item.nameRu ?? item.id!!.toString()),
            () => (item.nameRu ?? item.id!!.toString()),
        )();

        return `${code} - ${name}`;
    }
    // endregion


    // region Специфика/форма
    public specificityToReportFormKeyMap = common.specificityToReportFormKeyMap;

    public specificitiesLoading = false;

    public specificities: Array<Dict.EbkEk> = [];

    public get specificityOptions(): Array<Comp.DropdownItemDef<Dict.EbkEk>> {
        const result: Array<Comp.DropdownItemDef<Dict.EbkEk>> = [];

        this.specificities.forEach((specificity) => {
            const code = ((): string => {
                let result = String(specificity.spf);
                while (result.length < 3) {
                    result = '0' + result;
                }
                return result;
            })();

            const name = this.localize(
                () => (specificity.nameRu || ''),
                () => (specificity.nameKk || ''),
                () => (specificity.nameRu || ''),
            );

            result.push({
                text: `${code} - ${name}`,
                value: specificity,
            });
        });

        return result;
    }

    public specificity: Dict.EbkEk | null = common.store.specificity;

    public reloadSpecificities() {
        if (this.specificitiesLoading) {
            console.error('Cannot load specificities - another loading is running');
            return;
        }

        const codes = common.specificityCodes;
        const reportYear = this.reportYear;

        this.specificities = [];
        this.specificitiesLoading = true;

        Ax<Array<Dict.EbkEk>>(
            {
                url: this.prepareUrl(
                    '/api/budget/staffing_table/specificities',
                    [
                        ['codes', codes],
                        ['year', reportYear],
                    ],
                ),
            },
            (data) => {
                this.specificities = data.sort((specificity1, specificity2): number => {
                    const code1 = specificity1.spf ?? 0;
                    const code2 = specificity2.spf ?? 0;
                    return (code1 - code2);
                });
            },
            (error) => {
                this.toast(
                    'danger',
                    this.i18n.translate('cannot_load_specificities'),
                    error.toString(),
                );
            },
            () => {
                this.specificitiesLoading = false;

                if (this.specificity !== null) {
                    const id = this.specificity.id;
                    const index = this.specificities.findIndex((loadedSpecificity) => (loadedSpecificity.id === id));
                    if (index < 0) {
                        this.specificity = null;
                    } else {
                        this.specificity = this.specificities[index];
                    }
                }
            },
        );
    }

    public get formOptions(): Array<Comp.DropdownItemDef<Report.FormKey>> {
        const result: Array<Comp.DropdownItemDef<Report.FormKey>> = [];

        const specificity = this.specificity?.spf ?? null;
        if (specificity !== null) {
            const forms = this.specificityToReportFormKeyMap.get(specificity);
            // noinspection JSIncompatibleTypesComparison
            if (forms !== undefined) {
                forms.forEach((form) => {
                    let code = String(form);
                    if (code.toUpperCase().startsWith('F_')) {
                        code = code.substring(2);
                    }
                    code = code.replaceAll('_', '-');

                    result.push({
                        text: code,
                        value: form,
                    });
                });
            }
        }

        return result;
    }

    public form: Report.FormKey | null = common.store.form;
    public onFormChanged(form: Report.FormKey | null | undefined) {
        this.form = form ?? null;
    }
    // endregion


    // region Расширенный отчет, отладка
    public detailedReport = false;

    public debugging = false;
    // endregion


    // region Действия
    private generateReportUsingSettings(
        action: (
            regionCode: string,
            budgetVariantUUID: string,
            budgetProgramCode: number,
            budgetSubprogramCode: number | null,
            form: Report.FormKey,
            versionId: number,
            reportYear: number,
            detailedReport: boolean,
            debugging: boolean,
        ) => unknown,
    ) {
        const regionCode = this.region?.code ?? null;
        if (typeof regionCode !== 'string') {
            console.error('Cannot define region');
            return;
        }

        const budgetVariantUUID = this.budgetVariant?.variantUuid;
        if (typeof budgetVariantUUID !== 'string') {
            console.error('Cannot define budget variant UUID');
            return;
        }

        const budgetProgramCode = this.budgetProgram?.prg;
        if (typeof budgetProgramCode !== 'number') {
            console.error('Cannot define budget program code');
            return;
        }

        const form = this.form;
        if (form === null) {
            console.error('Report form is NULL');
            return;
        }

        const versionId = this.version?.id ?? null;
        if (typeof versionId !== 'number') {
            console.error('Version ID is null');
            return;
        }

        const budgetSubprogramCode = this.budgetSubprogram?.ppr ?? null;

        const reportYear = this.reportYear;
        const detailedReport = this.detailedReport;
        const debugging = this.debugging;

        action(regionCode, budgetVariantUUID, budgetProgramCode, budgetSubprogramCode, form, versionId, reportYear, detailedReport, debugging);
    }

    public generateAndDownloadReport() {
        this.generateReportUsingSettings((regionCode, budgetVariantUUID, budgetProgramCode, budgetSubprogramCode, form, versionId, reportYear, detailedReport, debugging) => {
            const url = this.prepareUrl(
                '/api/budget/staffing_table/report-v2/form-report.zip',
                [
                    ['region',            regionCode],
                    ['version',           versionId],
                    ['budget-variant',    budgetVariantUUID],
                    ['budget-program',    budgetProgramCode],
                    ['budget-subprogram', budgetSubprogramCode],
                    ['form',              form],
                    ['year',              reportYear],
                    ['detailed-report',   detailedReport],
                    ['debugging',         debugging],
                ],
            );
            this.openUrl(url);
        });
    }


    public generatingAndSavingReport = false;

    public generateAndSaveReport() {
        if (this.generatingAndSavingReport) {
            console.error('Cannot generate and save report - another generation is running');
        }

        this.generateReportUsingSettings((regionCode, budgetVariantUUID, budgetProgramCode, budgetSubprogramCode, form, versionId, reportYear, detailedReport, debugging) => {
            const url = this.prepareUrl(
                '/api/budget/staffing_table/report-v2/generate',
                [
                    ['version',           versionId],
                    ['region',            regionCode],
                    ['budget-variant',    budgetVariantUUID],
                    ['budget-program',    budgetProgramCode],
                    ['budget-subprogram', budgetSubprogramCode],
                    ['form',              form],
                    ['year',              reportYear],
                    ['detailed-report',   detailedReport],
                    ['debugging',         debugging],
                ],
            );

            this.generatingAndSavingReport = true
            this.request<Report2>(
                { url, method: 'POST' },
                (data) => {
                    this.$emit(events.reportSaved, data);
                },
                () => (this.i18nDb.translateByKey('modules.budget.staffing-table.reports.*GenerationPane*.errors.cannot-generate-and-save-report')),
                () => {
                    this.generatingAndSavingReport = false;
                },
            );
        });
    }
    // endregion
}
